import React, { Fragment } from "react";
import {
  List,
  TabbedShowLayout,
  Tab,
  Show,
  ReferenceManyField,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  DateField,
  Filter,
  SelectInput,
  EmailField,
  TextField,
  EditButton,
  DeleteButton,
  ShowButton,
  BooleanInput,
  ReferenceInput,
  TextInput,
  required,
  minLength,
  FileInput,
  BooleanField,
  FileField,
  email,
  CardActions,
  CreateButton,
  ExportButton,
  ShowController,
  FunctionField,

} from "react-admin";
import { NumberFieldik, SwitchExample, PdcField } from "../comonent";

import {Table, TableBody,TableRow, TableCell} from '@material-ui/core';
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import ApproveButton from "./ApproveButton";
import AddNewAddressButton from "./AddNewAddressButton";
import EditCreditButton from "./EditCreditsButton";
import AddNewCreditsButton from "./AddNewCreditsButton";
import "./style.css"
import addresses from "../orders/addresses";
import record from "admin-on-rest/lib/reducer/admin/record";
const confirmPassword = (value, allValues) => {
  if (value !== allValues.password) {
    return "Passwords mismatch";
  }
};
const validateFirstName = [required(), minLength(2)];
const validateLastName = [required(), minLength(2)];
const validateEmail = [required(), email()];
const validatePassword = [required(), minLength(8)];
const validateEditPassword = [minLength(6)];
const validatePhone = [required()];
const validateRePassword = [required(), minLength(8), confirmPassword];
const validateUsersGroup = [required()];
const validateCompanyName = [required()];

const accessControl = !JSON.parse(localStorage.getItem("permissions"))
  ? {}
  : JSON.parse(localStorage.getItem("permissions"));

  let devPermission = JSON.parse(localStorage.getItem('devPermission'))
    let deleteRow = false
    if(devPermission !== null && devPermission.deleteRow === true) {
        deleteRow = devPermission.deleteRow;
    }
const UserFilter = props => (
  <Filter {...props}>
    <TextInput label="Email" source="email" alwaysOn />
    <TextInput label="First Name" source="firstname" />
    <TextInput label="Last Name" source="lastname" />
  </Filter>
);

const PostActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}

    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
{accessControl["users"] &&
          accessControl["users"].includes("create") ? (
            <a href="/users/newUser/" className="custom-create-btn">
      <span className="MuiButton-label-220">
        <svg
          className="MuiSvgIcon-root-184 translate-Button--smallIcon-94"
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <g>
            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
          </g>
        </svg>
        <span className="translate-Button--label-92">Create</span>
      </span>
      <span className="MuiTouchRipple-root-76"></span>
    </a>
          ) : (
            ""
          )}
    
  </CardActions>
);
const EditButtonHandler = (props, {record}) => {
  return props.record.deleted ? "Deleted" :  <EditButton basePath={props.basePath} {...props}/>
}
export const UserList = (props) => {
  return (
    <List filters={<UserFilter />} {...props} bulkActions={deleteRow === true ? '' : false} actions={<PostActions />}>
      <Datagrid>
        <TextField source="id" />
        <EmailField source="email" />
        <TextField source="firstname" />
        <TextField source="lastname" />
        <FunctionField label="Is Member" render={record => record.isMember ? 'yes':'no'} />
        <BooleanField source="isActive"/>
        <BooleanField source="deleted"/>
        <BooleanField source="delayedPayment"/>
        <DateField source="createdAt" />
        {accessControl["users"] &&
        accessControl["users"].includes("edit") ? (
            <EditButtonHandler basePath="/users/editUser"/>
        ) : (
            ""
        )}

        <ShowButton basePath="/users" />
      </Datagrid>
    </List>
  );
};
const UserName = ({ record }) => {
  return (
    <span>User {record ? `"${record.firstname} ${record.lastname}"` : ""}</span>
  );
};

function saveReload() {
  window.location.reload();
}

export const UserShow = props => {
  return (
    <ShowController {...props}>
    {controllerProps =>

        <Show title={<UserName />} {...props} {...controllerProps}>
      <TabbedShowLayout className='user-show flexible-card-span-child column-4'>
        <Tab label="users">
           <TextField source="id"/>
           <TextField source="firstname" />
           <TextField source="lastname" />
           <TextField source="email" />
           <TextField source="type" />
           {/* <TextField source="group.name" /> */}

           <BooleanField source="isVerified" />
           <BooleanField source="isActive" />
           <BooleanField source="delayedPayment" />
           <BooleanField source="pdfApproved" />
           <BooleanField source="subscription" />
           <BooleanField source="isMember" />
           {controllerProps.record && controllerProps.record.parentEmail &&
            <TextField source="parentEmail"/>
           }
           {controllerProps.record && controllerProps.record.phone &&
            <TextField source="phone"/>
           }
          {controllerProps.record && controllerProps.record.companyName &&
          <TextField source="companyName"/>
          }

          {controllerProps.record && controllerProps.record.trnCertificate  &&
           <FileField
               source="trnCertificate"
               target="_blank"
               title="TRN certificate"
           />
          }
          {controllerProps.record && controllerProps.record.tradeLicense  &&
           <FileField
               source="tradeLicense"
               target="_blank"
               title="Trade License"
           />

          }

           <DateField label="Created date" source="createdAt" />
           <DateField label="Update date" source="updatedAt" />
          {
            ( controllerProps.record &&  controllerProps.record.deleted ) ?

            <ApproveButton data={props.id} isDisabled
            />
            :
                <ApproveButton data={props.id}
                />

          }


        </Tab>
        <Tab label="addresses" path="addresses">
          {accessControl["addresses"] &&
          accessControl["addresses"].includes("create") ? (
              controllerProps.record &&  controllerProps.record.deleted ?  <AddNewAddressButton isDisabled/>:
            <AddNewAddressButton />
          ) : (
            ""
          )}
          {accessControl["addresses"] &&
          accessControl["addresses"].includes("list")  ? (
            <ReferenceManyField
              reference="addresses"
              target="userId"
              addLabel={false}
            >
              <Datagrid>
                <TextField source="country.country_name" label="Country" />
                <TextField source="city" />
                <TextField source="firstname" />
                <TextField source="lastname" />
                <TextField source="street" />
                <BooleanField source="isDefaultBilling" />
                <BooleanField source="isDefaultShipping" />
                <BooleanField source="useAsShipping" />
                <EditButton />
                <DeleteButton basePath={`/users/${props.id}/show/addresses`}/>

              </Datagrid>
            </ReferenceManyField>
          ) : (
            ""
          )}
        </Tab>

        {controllerProps.record && controllerProps.record.parentId === 0?
         <Tab label="credits" path="credits">
           {
             accessControl["credits"] && accessControl["credits"].includes("edit") &&
             controllerProps.record.availableAmount !== null ?
                 <PdcField/> : ''
           }
           {accessControl["credits"] &&
            accessControl["credits"].includes("create") ?
               (controllerProps.record.availableAmount == null ? (controllerProps.record &&  controllerProps.record.deleted ?
                       <AddNewCreditsButton className='full-w mb-4' isDisabled/> : <AddNewCreditsButton className='full-w mb-4' />) :
                       <EditCreditButton className='full-w mb-4' />

               ) : ''
           }
          {accessControl["credits"] &&
          accessControl["credits"].includes("list") ? (
            <ReferenceManyField
              reference="credits"
              target="userId"
              addLabel={false}
              className='full-w'
            >
              {/*<TextField source="userId" />*/}
              <Datagrid>
                <NumberFieldik source="userId" />
                <NumberFieldik source="pdcOwnerId" />
                <TextField source="amount" />
                <TextField source="daysCount" />
                <TextField source="orderId" />
                <DateField source="createdAt" showTime={true} />
                <DateField source="endDate" showTime={true} />
                <SwitchExample source="paid" />
              </Datagrid>
            </ReferenceManyField>
          ) : (
            ""
          )}
          <Button color="primary"  style={{ color:"#fff", backgroundColor: "#B4B000"}} onClick = {saveReload} disabled={controllerProps.record.deleted}>Save</Button>
        </Tab>
        :
        ""}
		 {controllerProps.record && controllerProps.record.customers?
			  <Tab label="customers" path="customers">
				 <Table aria-label="simple table">
					<TableBody>
						<TableRow ><TableCell align="left">First Name</TableCell><TableCell align="left">Last Name</TableCell><TableCell align="left">Company</TableCell></TableRow> 
						{ Object.values(controllerProps.record.customers).map(function(value, index) {
							return <TableRow key={index}>
								<TableCell align="left">
									<div className="flex-box">
									{value.firstname}
									</div>
								</TableCell>
								<TableCell align="left">
									<div className="flex-box">
									{value.lastname}
									</div>
								</TableCell>
								<TableCell align="left">
									<div className="flex-box">
									{value.company}
									</div>
								</TableCell>
								</TableRow> 
						})}
					</TableBody>
				</Table>
			  </Tab>
          :""}
      </TabbedShowLayout>
    </Show>
    }
</ShowController>
    
  );
};

function formatLogo(value) {
  if (!value || typeof value === "string") {
    // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
    return { url: value };
  } else {
    // Else a new image is selected which results in a value object already having a preview link under the url key
    return value;
  }
}
var checked = false;
function checkedChangeAction(value) {
  if (value.target.checked) {
    checked = true;
  } else {
    checked = false;
  }
}

export const UserCreate = (props, record) => {
  return (
    <Create title="Create a User" {...props}>
      <SimpleForm encType="multipart/form-data">
        {/* <ReferenceInput label="User Group" source="groupId" reference="usersgroups" validate={validateUsersGroup}>
                <SelectInput optionText="name" optionValue="id"/>
            </ReferenceInput> */}
        <BooleanInput
          source="memberUser"
          label="Member User"
          onChange={checkedChangeAction}
        />
        <TextInput source="firstname" validate={validateFirstName} />
        <TextInput source="lastname" validate={validateLastName} />
        <TextInput source="email" type="email" validate={validateEmail} />
        {/* <TextInput source="phone" validate={validatePhone}/> */}
        <TextInput
          source="password"
          type="password"
          validate={validatePassword}
        />
        <TextInput
          source="repassword"
          label="Repeat Password"
          type="password"
          validate={validatePassword}
        />
        {/* <TextInput source="companyName" validate={validateCompanyName}/> */}
        <BooleanInput
          label="Verified"
          source="isVerified"
          defaultValue={true}
        />
        <BooleanInput label="Active" source="isActive" defaultValue={true} />
        <BooleanInput label="delayedPayment" source="delayedPayment" defaultValue={false} />
        <BooleanInput label="Deleted" source="deleted" defaultValue={false} />
        <FileInput
          source="files[0]"
          format={formatLogo}
          label="File"
          accept="application/pdf, image/*"
        >
          <FileField source="trnCertificate" title="title" />
        </FileInput>
        <FileInput
          source="files[1]"
          format={formatLogo}
          label="File"
          accept="application/pdf, image/*"
        >
          <FileField source="tradeLicense" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

export const UserEdit = props => (
  <Edit title={<UserName />} {...props}>
    <SimpleForm>
      <ReferenceInput
        label="User Group"
        source="groupId"
        reference="usersgroups"
        validate={validateUsersGroup}
      >
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <TextInput source="firstname" validate={validateFirstName} />
      <TextInput source="lastname" validate={validateLastName} />
      <TextInput source="email" type="email" validate={validateEmail} />
      <TextInput source="phone" validate={validatePhone} />
      <TextInput source="companyName" />
      <BooleanInput label="Verified" source="isVerified" />
      <BooleanInput label="Active" source="isActive" />
      <BooleanInput label="delayedPayment" source="delayedPayment" />
      <BooleanInput label="Deleted" source="deleted" />
      <BooleanInput label="pdfApproved" source="pdfApproved" />
      <FileInput
        source="files[0]"
        format={formatLogo}
        label="File"
        accept="application/pdf, image/*"
      >
        <FileField source="trnCertificate" title="title" />
      </FileInput>
      <FileInput
        source="files[1]"
        format={formatLogo}
        label="File"
        accept="application/pdf, image/*"
      >
        <FileField source="tradeLicense" title="title" />
      </FileInput>
    </SimpleForm>
  </Edit>
);
