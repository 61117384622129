import React from "react";
import './style.css';
import {
  List,
  Show,
  SimpleShowLayout,
  ReferenceInput,
  Datagrid,
  DateField,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  ShowButton,
  DeleteButton,
  DisabledInput,
  BooleanField,
  BooleanInput,
  TextInput,
  SelectInput,
  LongTextInput,
  RichTextField,
  ImageField,
  ImageInput,
  DateInput,
  Filter,
  Button,
  TabbedForm,
  FormTab,
  ReferenceManyField,
  required,
    regex
} from "react-admin";

//import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import RichTextInput from "ra-input-rich-text";

const validateName = [required()];
const validateCode = [required(),regex(/^[a-zA-Z0-9_.-]*$/,"Coupon code must include only numbers and letters")];
const validateCount = [required()];
const validateFile = [required()];
const validateDescription = [required()];
const validateStatus = [required()];
const validateVisibility = [required()];
const validateUrl = [required()];
const CategoriesFilter = props => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
    <TextInput label="Key" source="key" />
  </Filter>
);
export const CouponsList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="couponCode" />
      <EditButton basePath="/coupons" />
      {/*<DeleteButton basePath="/products" />*/}
    </Datagrid>
  </List>
);

const CouponTitle = ({ record }) => {
  return <span>
    Category {record ? `"${record.name}"` : ""}
  </span>;
};
function formatLogo(value) {
  if (!value || typeof value === "string") {
    // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
    return { url: value };
    alert(true)
  } else {
    // Else a new image is selected which results in a value object already having a preview link under the url key
    return value;
    alert(false)
  }
}

export const CouponsEdit = props => (
  <Edit title={<CouponTitle />} {...props}>
    <TabbedForm className="category-edit ra-input-bordered flexible-mui-span-child content-inner-pt content-inner-pb column-3">
      <FormTab label="summary" >
        <DisabledInput source="id" />
        <TextInput source="name" validate={validateName} />
        <TextInput source="couponCode" validate={validateCode} />
        <TextInput source="usageCount" validate={validateCount} />
        <TextInput source="percent" validate={validateCount} />
        <DateInput source="expireDate" validate={validateCount} />
        <SelectInput
          source="active"
          validate={validateStatus}
          choices={[
            { id: true, name: "Active" },
            { id: false, name: "Inactive" }
          ]}
        />
      </FormTab>

    </TabbedForm>
  </Edit>
);

export const CouponsCreate = props => (
  <Create title="Create Coupon" {...props}>
    <SimpleForm encType="multipart/form-data" className="category-create ra-input-bordered flexible-mui-child column-3">
      <DisabledInput source="id" />
      <TextInput source="name" validate={validateName} />
      <TextInput source="couponCode" validate={validateCode} />
      <TextInput source="usageCount" validate={validateCount} />
      <TextInput source="percent" validate={validateCount} />
      <DateInput source="expireDate" validate={validateCount} />
      <SelectInput
          source="active"
          validate={validateStatus}
          choices={[
            { id: true, name: "Active" },
            { id: false, name: "Inactive" }
          ]}
      />
    </SimpleForm>
  </Create>
);

export const CouponsShow = props => (
  <Show title={<CouponTitle />} {...props}>
    <SimpleShowLayout className="category-show ra-field-bordered flexible-child ra-field-column-3">
      <TextField source="name" />
      <TextField source="key" />
      <DateField label="Publication date" source="createdAt" />
      <ImageField className="gallery-image-list" multiple source="galleries" src="file" />
    </SimpleShowLayout>
  </Show>
);
