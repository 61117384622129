import React, {Component} from 'react';

import {
    FormControl,
    TextField,
    Link,
    InputLabel,
    Select,
    MenuItem,
    TableBody,
    TableRow,
    TableCell, Table, FormLabel
} from '@material-ui/core';
import {
    Button,
} from '@material-ui/core';

import axios from "axios";
import authHeader from "../_helpers/authHeader";
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import {
    Col,
    Row
} from "reactstrap";

import UsersPopup from "../users/usersPopup";
import ProductsPopup from "../products/productsPopup";
import MembersList from "./membersList";
import AdminsPopup from "./adminsPopup"
import AdminsList from "./adminsList"
import PaymentMethods from "./paymentMethods"
import ShippingMethods from "./shippingMethods"
import Statuses from "./statuses"
import SendEmailCheckbox from "./sendEmailCheckbox"
import SendPushCheckbox from "./sendPushCheckbox"
import SendPushToWebCheckbox from "./sendPushWeb"
import Products from "./products"
import Weight from "./weight"
import Addresses from "./addresses"
import AddressesForm from "./addressesForm"
import ViewFiles from "./viewFiles"
import OrderTypes from "./orderTypes"
import OrderTypePrices from './orderTypePrice'
import ErrorDialog from "./errorDialog"
import DeleteIcon from "@material-ui/icons/Delete";
import {red} from "@material-ui/core/colors";
import Messenger from "../chat";
import Input from '@material-ui/core/Input';

const accessControl = !JSON.parse(localStorage.getItem("permissions"))
    ? {}
    : JSON.parse(localStorage.getItem("permissions"));

let devPermission = JSON.parse(localStorage.getItem('devPermission'))
let showOrderUploadBtn = false
if (devPermission !== null && devPermission.showOrderUploadBtn === true) {
    showOrderUploadBtn = devPermission.showOrderUploadBtn;
}

class Order extends Component {
    constructor(props) {
        super(props);
        this.state = {
            field: {
                userId: null,
                selectedEmail: "",
                groupId: null,
                statusId: 0,
                orderId: "",
                isTesting: 0,
                price: 0,
                salePrice: 0,
                discount: 0,
                shippingPrice: 0,
                vat: 0,
                perColorPrice: 0,
                totalExclVat: 0,
                totalInclVat: 0,
                paid: false,
                weight: 0,
                platform: "admin",
                status: "open",
                deleted: 0,

                deviceId: "admin",
                statusDescription: ""
            },
            userDeleted: false,
            dieCutTemplate: {},
            productType: '',
            statusById: 'open',
            product: {},
            productPrice: {},
            orderProduct: {},
            files: [],
            hasLenFile: false,
            webCenterFiles: [],
            supportAdmins: {},
            billingAddressObj: {
                saveAsNewAddress: false,
                firstname: "",
                middlename: "",
                lastname: "",
                company: "",
                street: "",
                city: "",
                countryId: null,
                countryCode: "",
                region: "",
                postcode: "",
                telephone: "",
                fax: ""
            },
            shippingAddressObj: {
                sameAsBilling: false,
                saveAsNewAddress: false,
                firstname: "",
                middlename: "",
                lastname: "",
                company: "",
                street: "",
                city: "",
                countryId: null,
                countryCode: "",
                region: "",
                postcode: "",
                telephone: "",
                fax: ""
            },
            previousShippingAddressObj: {},
            shippingAddressId: null,
            billingAddressId: null,
            paymentMethod: null,
            shippingMethod: null,
            orderTypeId: null,
            orderTypesValue: null,
            orderTypePriceObj: {
                lenPrice: '',
                pdfPrice: '',
                physicalPrice: '',
                lenPriceDiscount: '',
                pdfPriceDiscount: '',
                physicalPriceDiscount: '',
                proofingPrice: '',
                proofingPriceDiscount: ''
            },
            byColorsObj: {},
            byColorPrice: {},
            sendEmail: false,
            sendPush: false,
            sendPushToWeb: false,
            members: {},
            editPage: false,
            projectName: "",
            projectDescription: "",
            currierName: "",
            trackingNumber: "",
            trackingNumberUrl: "",
            folderId: process.env.REACT_APP_FOLDERID_CUSTOMER,
            error: {
                show: false,
                text: ""
            },
            weightError: false,
            _pageIsReady: false
        };
        this.handleChangeUserValue = this.handleChangeUserValue.bind(this);
        this.handleChangeProductValue = this.handleChangeProductValue.bind(this);
        this.handleChangeMemberValue = this.handleChangeMemberValue.bind(this);
        this.handleChangeSupportAdminValue = this.handleChangeSupportAdminValue.bind(this);
        this.handleProductChange = this.handleProductChange.bind(this);
        this.handleFileSelect = this.handleFileSelect.bind(this);
        this.handleLenFile = this.handleLenFile.bind(this);
        this.handlePaymentChange = this.handlePaymentChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleSendEmailChange = this.handleSendEmailChange.bind(this);
        this.handleSendPushChange = this.handleSendPushChange.bind(this);
        this.handleSendPushToWebChange = this.handleSendPushToWebChange.bind(this);
        this.handleWeightChange = this.handleWeightChange.bind(this);
        this.handleShippingChange = this.handleShippingChange.bind(this);
        this.handleBillingAddressChange = this.handleBillingAddressChange.bind(this);
        this.handleShippingAddressChange = this.handleShippingAddressChange.bind(this);
        this.handleBillingAddressObjChange = this.handleBillingAddressObjChange.bind(this);
        this.handleShippingAddressObjChange = this.handleShippingAddressObjChange.bind(this);
        this.handleShippingAddressCheckboxChange = this.handleShippingAddressCheckboxChange.bind(this);
        this.handleProjectData = this.handleProjectData.bind(this);
        this.handleCurrierName = this.handleCurrierName.bind(this);
        this.handleTrackingNumber = this.handleTrackingNumber.bind(this);
        this.handleTrackingUrl = this.handleTrackingUrl.bind(this);
        this.handleChangeErrorPopup = this.handleChangeErrorPopup.bind(this);
        this.deleteMember = this.deleteMember.bind(this);
        this.deleteSupportAdmins = this.deleteSupportAdmins.bind(this);
        this.handleViewFiles = this.handleViewFiles.bind(this);
        this.handleOrderColorsObj = this.handleOrderColorsObj.bind(this);
        this.handleOrderTypeChange = this.handleOrderTypeChange.bind(this);
        this.handleOrderTypePricesObjChange = this.handleOrderTypePricesObjChange.bind(this);
        this.handleChangeFolderName = this.handleChangeFolderName.bind(this);

        this.validationForm = this.validationForm.bind(this);
        this.createOrder = this.createOrder.bind(this);
        this.sendJobOrder = this.sendJobOrder.bind(this);
        this.sendInvoice = this.sendInvoice.bind(this);
        this.handleProductPrice = this.handleProductPrice.bind(this);
        this.handleProduct = this.handleProduct.bind(this);
        this.calcTotalExclVat = this.calcTotalExclVat.bind(this);
        this.calcVat = this.calcVat.bind(this);
        this.calcTotalInctVat = this.calcTotalInctVat.bind(this);
        this.financial = this.financial.bind(this);
        this.handlePlatePriceChange = this.handlePlatePriceChange.bind(this);
        this.handleSaveProduct = this.handleSaveProduct.bind(this);
        this.init = this.init.bind(this);

    }


    handleProductPrice = childData => {
        if (childData) {
            this.setState({
                productPrice: childData
            });
            this.state.field.price = childData.price;
            this.state.field.discount = childData.salePrice;
            this.state.field.perColorPrice = childData.per_price;
        }

    }

    handleProduct = childData => {
        if (childData) {
            this.setState({
                product: childData
            })
        }
    };

    handleDeleteProduct = () => {
        this.setState({
            product: {}
        });
        this.state.field.price = 0;
        this.state.field.discount = 0;
        this.state.field.vat = 0;
        this.state.field.totalExclVat = 0;
        this.state.field.totalInclVat = 0;

    };

    calcTotalExclVat() {
        let totalExclVat = this.state.field.price - this.state.field.discount + this.state.field.shippingPrice;
        this.state.field.totalExclVat = totalExclVat;
        return totalExclVat;
    }

    calcVat() {
        let vat = (this.state.field.price - this.state.field.discount + this.state.field.shippingPrice) * 5 / 100;
        this.state.field.vat = vat;
        return vat;
    }

    calcTotalInctVat() {
        let total_price = this.state.field.price - this.state.field.discount + this.state.field.shippingPrice;
        this.state.field.totalInclVat = total_price + total_price * 5 / 100;
        return total_price + total_price * 5 / 100;
    }

    financial(x) {
        return Number.parseFloat(x).toFixed(2);
    }

    validationForm() {
        let ApproveFileNotExist = true,
            hasWebCenterLen = false;
        if (this.state.webCenterFiles.length != 0) {
            for (let elem of this.state.webCenterFiles) {
                if (elem.folderName == 'Soft Approve') {
                    ApproveFileNotExist = false;
                    break;
                } else {
                    ApproveFileNotExist = true;
                }
            }
            for (let elem of this.state.webCenterFiles) {

                if (elem.folderName == "LEN") {
                    hasWebCenterLen = true;
                    break;
                }
            }
        }

        if (this.state.orderTypeId === null && this.state.editPage && (this.state.field.statusId === 17 || this.state.field.statusId === 18 || this.state.field.statusId === 5)) {
            this.setState({error: {text: "Please select Order type", show: true}});
            return true;
        }
        // if(!hasWebCenterLen && !this.state.hasLenFile && this.state.editPage && this.state.field.statusId==6 && this.state.productType !== 'fingerprint'){
        //     let errMessage = "Please, attach a LEN file...";
        //     this.setState({error: {text: errMessage ,show: true}});
        //     return true;
        // }
        // if(!hasWebCenterLen && this.state.editPage && this.state.field.statusId==6 && this.state.productType !== 'fingerprint'){
        //     let errMessage = "LEN file is not available in WebCenter";
        //     this.setState({error: {text: errMessage ,show: true}});
        //     return true;
        // }
        if (!this.state.field.statusId) {
            this.setState({error: {text: "Please select Order status", show: true}});
            return true;
        }

        if (this.state.field.userId === null) {
            this.setState({error: {text: "Please select user", show: true}});
            return true;
        }
        if (!this.state.field.isTesting && this.state.files.length === 0 && this.state.webCenterFiles.length === 0) {
            this.setState({error: {text: "Please select file", show: true}});
            return true;
        }
        if (this.state.projectName === "") {
            this.setState({error: {text: "Please write project name", show: true}});
            return true;
        }
        if (this.state.field.statusId == 18) {
            if (this.state.currierName !== "" && this.state.trackingNumber === "") {
                this.setState({error: {text: "Please write Tracking Number", show: true}});
                return true;

            }
            if (this.state.trackingNumber !== "" && this.state.currierName === "") {
                this.setState({error: {text: "Please write Currier name", show: true}});
                return true;
            }
        }
        // if(this.state.currierName === "" && this.state.field.statusId == 18){
        //     this.setState({error: {text: "Please write Currier name" ,show: true}});
        //     return true;
        // }
        // if(this.state.trackingNumber === "" && this.state.field.statusId == 18){
        //     this.setState({error: {text: "Please write Tracking Number" ,show: true}});
        //     return true;
        // }
        if (this.state.projectDescription === "") {
            this.setState({error: {text: "Please write project description", show: true}});
            return true;
        }
        if ((this.state.field.statusId == 17 || this.state.field.statusId == 18 || this.state.field.statusId == 5) && this.state.editPage && this.state.field.weight > 0 && (this.state.shippingAddressId === null && this.state.shippingAddressObj.countryId === null)) {
            this.setState({error: {text: "Please select shipping address", show: true}});
            return true;
        }
        if ((this.state.field.statusId == 17 || this.state.field.statusId == 18 || this.state.field.statusId == 5) && this.state.editPage && this.state.field.weight > 0 && (this.state.billingAddressId === null && this.state.billingAddressObj.countryId === null)) {
            this.setState({error: {text: "Please select billing address", show: true}});
            return true;
        }
        if ((this.state.field.statusId == 17 || this.state.field.statusId == 18 || this.state.field.statusId == 5) && this.state.editPage && this.state.paymentMethod === null) {
            this.setState({error: {text: "Please select Payment method", show: true}});
            return true;
        }
        if (this.state.orderTypeId == 3 && (this.state.field.statusId == 17 || this.state.field.statusId == 18 || this.state.field.statusId == 5) && this.state.editPage && this.state.field.weight > 0 && (this.state.shippingAddressId !== null || this.state.shippingAddressObj.countryId !== null) && this.state.shippingMethod === null) {
            this.setState({error: {text: "Please select shipping method", show: true}});
            return true;
        }
        if (Object.keys(this.state.product).length === 0) {
            this.setState({error: {text: "Please select Product with Options", show: true}});
            return true;
        }
        if (this.state.field.statusId == 6) {
            if (this.state.productType === 'fingerprint') {
                if ((this.state.orderTypePriceObj.physicalPrice == 0 || this.state.orderTypePriceObj.physicalPrice == null || this.state.orderTypePriceObj.physicalPrice == '') ||
                    (this.state.orderTypePriceObj.proofingPrice == 0 || this.state.orderTypePriceObj.proofingPrice == null || this.state.orderTypePriceObj.proofingPrice == '')) {
                    this.setState({error: {text: "Order type prices must be greater than 0", show: true}});
                    return true;
                }
            } else {
                if (
                    (this.state.orderTypePriceObj.lenPrice == 0 || this.state.orderTypePriceObj.lenPrice == null || this.state.orderTypePriceObj.lenPrice == '') ||
                    (this.state.orderTypePriceObj.pdfPrice == 0 || this.state.orderTypePriceObj.pdfPrice == null || this.state.orderTypePriceObj.pdfPrice == '') ||
                    (this.state.orderTypePriceObj.physicalPrice == 0 || this.state.orderTypePriceObj.physicalPrice == null || this.state.orderTypePriceObj.physicalPrice == '') ||
                    (this.state.orderTypePriceObj.proofingPrice == 0 || this.state.orderTypePriceObj.proofingPrice == null || this.state.orderTypePriceObj.proofingPrice == '')) {
                    this.setState({error: {text: "Order type prices must be greater than 0", show: true}});
                    return true;
                }
            }

        }

        if (this.state.field.statusId == 6 && Object.keys(this.state.byColorPrice).length > 0 && this.state.productType !== 'fingerprint') {
            const capitalize = (s) => {
                if (typeof s !== 'string') return ''
                return s.charAt(0).toUpperCase() + s.slice(1)
            }
            for (let [key, value] of Object.entries(this.state.byColorPrice)) {
                let items = this.state.byColorPrice[key]
                for (let [item, key] of Object.entries(items)) {
                    if (items[item]['price'] === 0 || items[item]['price'] === '' || items[item]['price'] == null) {
                        this.setState({error: {text: `${capitalize(item)} prices must be greater than 0`, show: true}});
                        return true
                    }
                }
            }
        }
        if (this.state.editPage && this.state.field.statusId == 6 && this.state.productType !== 'fingerprint' && (ApproveFileNotExist && (this.state.folderId != 'Soft Approve' || this.state.files.length === 0))) {
            this.setState({error: {text: "Please upload file to Soft Approve folder", show: true}});
            return true;
        }

        if (this.state.field == '') {
            this.setState({error: {text: "Please write product Weight", show: true}});
            return true;
        }

        if (this.state.field.statusId == 6) {
            if (this.state.field.weight == '' || this.state.field.weight == 0 || this.state.field.weight == null) {
                this.setState({error: {text: "Weight must be greater than 0", show: true}});
                return true;
            }
        }

        return false;

    }

    convertArrayToObject = (array, key) => {
        const initialValue = {};
        return array.reduce((obj, item) => {
            return {
                ...obj,
                [item[key]]: item,
            };
        }, initialValue);
    };

    sendJobOrder() {
        const globalLoader = document.getElementById('global-loader');
        globalLoader.classList.add("show");
        const getParams = this.props.match.params;
        let axiosRequestUrl = `${process.env.REACT_APP_API_URL}/generateMails/joborder/${getParams.id}`;
        axios.get(axiosRequestUrl, {
            headers: {Authorization: authHeader().Authorization}
        }).then((response) => {
            if (response.data.payload) {
                window.location.reload();
            }
            if (response.data.error) {
                // hide loader
                globalLoader.classList.remove("show");
                let errMessage = "Something wrong";
                if (response.data.error.message.length > 0) {
                    errMessage = response.data.error.message;
                }
                this.setState({error: {text: errMessage, show: true}})
            }
        });
    }

    sendInvoice() {
        const globalLoader = document.getElementById('global-loader');
        globalLoader.classList.add("show");
        const getParams = this.props.match.params;
        let axiosRequestUrl = `${process.env.REACT_APP_API_URL}/generateMails/invoice/${getParams.id}`;
        axios.get(axiosRequestUrl, {
            headers: {Authorization: authHeader().Authorization}
        }).then((response) => {
            if (response.data.payload) {
                window.location.reload();
            }
            if (response.data.error) {
                // hide loader
                globalLoader.classList.remove("show");
                let errMessage = "Something wrong";
                if (response.data.error.message.length > 0) {
                    errMessage = response.data.error.message;
                }
                this.setState({error: {text: errMessage, show: true}})
            }
        });
    }

    handleSaveProduct = props => {
        const globalLoader = document.getElementById('global-loader');
        const getParams = this.props.match.params;
        const {
            field,
            product,
        } = this.state;
        let axiosRequestUrl = `${process.env.REACT_APP_API_URL}/order-options/${getParams.id}`;
        const formData = new FormData();

        if (props) {
            globalLoader.classList.add("show");

            let data = {
                userId: field.userId,
                price: field.price,
                salePrice: field.salePrice,
                discount: field.discount
            }

            formData.append('field', JSON.stringify(data));
            formData.append('product', JSON.stringify(product));
            axios.post(axiosRequestUrl, formData, {
                headers: {
                    Authorization: authHeader().Authorization, "content-type": "application/json",
                }
            }).then((response) => {
                if (response.data.code === 200) {
                    if (this.state.editPage) {
                        this.init()
                    }
                }
                if (response.data.code === 400) {
                    // hide loader
                    if (globalLoader.classList.contains("show")) {
                        globalLoader.classList.remove("show");
                    }
                    let errMessage = "Something wrong";
                    if (response.data.message.length > 0) {
                        errMessage = response.data.message;
                    }
                    this.setState({error: {text: errMessage, show: true}})
                }
            });
        }
    };


    createOrder() {
        if (this.validationForm()) {
            return;
        }

        // show loader
        const globalLoader = document.getElementById('global-loader');
        globalLoader.classList.add("show");

        const {
            field,
            files,
            members,
            product,
            supportAdmins,
            shippingAddressId,
            billingAddressId,
            shippingAddressObj,
            billingAddressObj,
            paymentMethod,
            shippingMethod,
            orderTypeId,
            orderTypePriceObj,
            sendEmail,
            sendPush,
            sendPushToWeb,
            projectName,
            projectDescription,
            currierName,
            trackingNumber,
            trackingNumberUrl,
            folderId,
            byColorPrice
        } = this.state;
        const formData = new FormData();
        if (files.length > 0) {
            files.map(function (element, index) {
                formData.append(index, element)
            })

        }
        formData.append('field', JSON.stringify(field));
        formData.append('members', JSON.stringify(members));
        formData.append('product', JSON.stringify(product));
        formData.append('supportAdmins', JSON.stringify(supportAdmins));
        formData.append('shippingAddressId', shippingAddressId);
        formData.append('billingAddressId', billingAddressId);
        formData.append('shippingAddressObj', JSON.stringify(shippingAddressObj));
        formData.append('billingAddressObj', JSON.stringify(billingAddressObj));
        formData.append('paymentMethod', paymentMethod);
        formData.append('shippingMethod', shippingMethod);
        formData.append('orderTypeId', orderTypeId);
        formData.append('sendEmail', sendEmail);
        formData.append('sendPush', sendPush);
        formData.append('sendPushToWeb', sendPushToWeb);
        formData.append('projectName', projectName);
        formData.append('projectDescription', projectDescription);
        formData.append('currierName', currierName);
        formData.append('trackingNumber', trackingNumber);
        formData.append('trackingNumberUrl', trackingNumberUrl);
        formData.append('folderId', folderId);
        if (Object.keys(byColorPrice).length > 0) {
            orderTypePriceObj['byColors'] = byColorPrice
            formData.append('byColorPrice', JSON.stringify(byColorPrice));
            formData.append('orderTypePriceObj', JSON.stringify(orderTypePriceObj));

        } else {
            formData.append('orderTypePriceObj', JSON.stringify(orderTypePriceObj));
        }

        let axiosRequestUrl = `${process.env.REACT_APP_API_URL}/orders`;
        if (this.state.editPage) {
            const getParams = this.props.match.params;
            axiosRequestUrl = `${process.env.REACT_APP_API_URL}/orders/${getParams.id}`;
        }


        axios.post(axiosRequestUrl, formData,
            {
                headers: {Authorization: authHeader().Authorization, 'Content-Type': 'multipart/form-data'}
            }).then((response) => {
            if (response.data.code === 200 && response.data.orderKey) {
                if (this.state.editPage) {
                    window.location.reload();

                } else {
                    window.location = '/orders'
                }
            }
            if (response.data.code === 400) {
                // hide loader
                if (globalLoader.classList.contains("show")) {
                    globalLoader.classList.remove("show");
                }
                let errMessage = "Something wrong";
                if (response.data.message.length > 0) {
                    errMessage = response.data.message;
                }
                this.setState({error: {text: errMessage, show: true}})
            }
        });

    }

    deleteMember(val) {
        let {members} = this.state;
        delete members[val];
        this.setState(members)
    }

    deleteSupportAdmins(val) {
        let {supportAdmins} = this.state;
        delete supportAdmins[val];
        this.setState(supportAdmins)
    }

    handleChangeUserValue(userId, email, accountInfo) {

        const {field, members} = this.state;


        if (userId !== field.userId) {
            Object.keys(members).forEach(k => delete members[k])
            this.setState(members);
        }
        field.userId = userId;
        if ("groupId" in accountInfo) {
            field.groupId = accountInfo['groupId']
        }
        //Object.keys(members).forEach(k => delete members[k])
        this.setState(field);
        //this.setState(members);
    }

    handleChangeProductValue(product) {
        const {field} = this.state;
        this.setState(field);
    }

    handleChangeMemberValue(userId, selectedValue) {
        const {members} = this.state;
        if (userId) {
            members[userId] = selectedValue;
            this.setState(members);
        }
    }

    handleChangeSupportAdminValue(adminId, selectedValue) {
        const {supportAdmins} = this.state;
        if (adminId) {
            supportAdmins[adminId] = selectedValue;
            this.setState(supportAdmins);
        }
    }

    handleProductChange(val) {
        this.setState(prevState => ({field: {...this.state.field, isTesting: !prevState.field.isTesting}}))
    }

    handleFileSelect(val) {
        let {files} = this.state
        // let index = this.state.files.length +1;
        let file = val.target.files;
        Object.values(file).map(function (element, i) {
            files.push(element)
        });

        this.setState(files)
    }

    handleLenFile(val) {
        let {files} = this.state;
        let file = val.target.files;

        Object.values(file).map(function (element, i) {
            let fileName = element.name,
                idxDot = fileName.lastIndexOf(".") + 1,
                extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if (extFile == "len") {

                if (element.type == '') {
                    const lenElement = new File([element], element.name, {type: 'len'});
                    files.push(lenElement);
                } else {
                    files.push(element);
                }
            }
        });

        this.setState({
            files: files,
            hasLenFile: true
        })
    }

    handlePaymentChange(val) {
        if (val.target.name === "payment") {
            this.setState({paymentMethod: val.target.value});
        } else if (val.target.name === "paid") {
            this.setState(prevState => ({field: {...this.state.field, paid: !prevState.field.paid}}))
        }
    }

    handleStatusChange(val) {
        const {field} = this.state;
        if (val.target.name === "description") {
            field.statusDescription = val.target.value;
        } else {
            field.statusId = val.target.value;
            field.statusDescription = "";
        }
        this.setState(field);
    }

    handleSendEmailChange(e) {
        let val = e.target.checked
        this.setState({
            sendEmail: val
        });
    }

    handleSendPushChange(e) {
        let val = e.target.checked
        this.setState({
            sendPush: val
        });
    }

    handleSendPushToWebChange(e) {
        let val = e.target.checked
        this.setState({
            sendPushToWeb: val
        });
    }

    setInputFilter(textBox, inputFilter) {
        ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function (event) {
            textBox.addEventListener(event, function () {

                if (inputFilter(this.value)) {
                    this.oldValue = this.value;
                } else if (this.hasOwnProperty("oldValue")) {
                    this.value = this.oldValue;
                } else {
                    this.value = "";
                }
            });
        });
    }

    handleWeightChange(val) {
        const {field} = this.state;
        this.setState({weightError: false});
        let value = val.target.value;
        this.setInputFilter(val.target, function (value) {
            return /^\d*[.,]?\d{0,3}$/.test(value) && (value === "" || parseInt(value, 0) <= 999999)
        });
        if (value < 0 || value === '') {
            this.setState({weightError: true});
            value = ''
        }

        field.weight = value;
        this.setState(field);
    }

    handleShippingChange(val) {

        this.setState({shippingMethod: val.target.value});
        const {field} = this.state;
        field.shippingPrice = parseFloat(val.target.name);
        this.setState(field);
    }

    handleBillingAddressChange(val) {
        this.setState({billingAddressId: val.target.value});
    }

    handleShippingAddressChange(val) {
        this.setState({shippingAddressId: val.target.value});
    }

    handleBillingAddressObjChange(evt) {
        if (evt.target) {
            if (evt.target.name === "saveAsNewAddress") {
                this.setState(prevState => ({
                    billingAddressObj: {
                        ...this.state.billingAddressObj,
                        saveAsNewAddress: !prevState.billingAddressObj.saveAsNewAddress
                    }
                }))
                return;
            }
            let name = evt.target.name;
            let value = evt.target.value;
            let {billingAddressObj} = this.state;
            billingAddressObj[name] = value;

            this.setState(billingAddressObj);
        } else {
            this.setState({billingAddressObj: evt})
        }
        if (this.state.shippingAddressObj.sameAsBilling) {
            let status = this.state.shippingAddressObj.sameAsBilling;
            this.setState({shippingAddressObj: {...this.state.billingAddressObj, sameAsBilling: status}})
        }
        this.setState({shippingAddressObj: {...this.state.billingAddressObj, sameAsBilling: true}});

        if (this.state.orderTypeId != 3 || this.state.orderTypeId != null) {
            this.setState({shippingAddressObj: {...this.state.billingAddressObj, sameAsBilling: true}});
            this.setState({shippingMethod: '4'});
            // const { field } = this.state;
            // field.weight = 1;
            // this.setState(field);
        }
    }

    handleShippingAddressObjChange(evt) {
        let {shippingAddressObj} = this.state;
        this.setState({previousShippingAddressObj: {...shippingAddressObj}})
        if (!evt.target) {
            this.setState({shippingAddressObj: evt})
        } else if (evt.target.name === "sameAsBilling") {
            this.setState(prevState => ({
                shippingAddressObj: {
                    ...this.state.shippingAddressObj,
                    sameAsBilling: !prevState.shippingAddressObj.sameAsBilling
                }
            }))
            let {shippingAddressObj} = this.state;
            let sameAsBillingStatus = shippingAddressObj.sameAsBilling;
            let status = !sameAsBillingStatus;
            if (status) {
                this.setState({shippingAddressObj: {...this.state.billingAddressObj, sameAsBilling: status}})
            } else {
                this.setState({shippingAddressObj: {...this.state.previousShippingAddressObj, sameAsBilling: status}})
            }
        } else if (evt.target.name === "saveAsNewAddress") {
            this.setState(prevState => ({
                shippingAddressObj: {
                    ...this.state.shippingAddressObj,
                    saveAsNewAddress: !prevState.shippingAddressObj.saveAsNewAddress
                }
            }))
        } else {
            let name = evt.target.name;
            let value = evt.target.value;
            let {shippingAddressObj} = this.state;
            shippingAddressObj[name] = value;
            this.setState(shippingAddressObj);
        }
    }

    handleShippingAddressCheckboxChange(val) {
        const {shippingAddressObj} = this.state;
        shippingAddressObj.sameAsBilling = val.target.value;
        this.setState(shippingAddressObj);
    }

    handleProjectData(val) {
        if (val.target.name === "name") {
            this.setState({projectName: val.target.value})
        } else {
            this.setState({projectDescription: val.target.value})

        }
    }

    handleCurrierName(val) {
        this.setState({currierName: val.target.value})
    }

    handleTrackingNumber(val) {
        this.setState({trackingNumber: val.target.value})
    }

    handleTrackingUrl(val) {
        this.setState({trackingNumberUrl: val.target.value})
    }

    handleChangeErrorPopup(val) {
        const {error} = this.state;
        error.show = false;
        this.setState(error)
    }

    handleViewFiles(val) {
    }

    handleOrderTypeChange(val) {
        this.setState({orderTypeId: val.target.value});
        if (this.state.orderTypeId != 3 || this.state.orderTypeId != null) {
            this.setState({shippingAddressObj: {...this.state.billingAddressObj, sameAsBilling: true}});
            this.setState({shippingMethod: '4'});
            // const {field,orderTypePriceObj} = this.state;
            // field.weight = 1;
            // orderTypePriceObj.lenPrice=0;
            // orderTypePriceObj.pdfPrice=0;
            // orderTypePriceObj.physicalPrice=0;
            // orderTypePriceObj.lenPriceDiscount=0;
            // orderTypePriceObj.pdfPriceDiscount=0;
            // orderTypePriceObj.physicalPriceDiscount=0;
            // this.setState(field);
            // this.setState(orderTypePriceObj);
        }
    }

    handleOrderTypePricesObjChange(evt) {
        if (evt.target) {
            let name = evt.target.name;

            let value = evt.target.value;

            if (name === 'lenPrice' || name === 'physicalPrice' || name === 'pdfPrice' || name === 'proofingPrice') {

                var referenceNode = evt.target


                if (parseInt(value) <= 0) {
                    if (!referenceNode.classList.contains('error')) {
                        referenceNode.classList.add('error')
                        var newNode = document.createElement('div');
                        newNode.style.cssText = 'position: absolute;bottom: -16px;font-size: 10px;color: red;';
                        newNode.textContent = "Field value must be greater than 0";
                        referenceNode.after(newNode);
                    }
                } else {
                    if (referenceNode.classList.contains('error')) {
                        referenceNode.classList.remove('error');
                        referenceNode.nextSibling.remove();
                    }
                    let {orderTypePriceObj} = this.state;
                    orderTypePriceObj[name] = value;
                    this.setState(orderTypePriceObj);
                }
            } else {
                let {orderTypePriceObj} = this.state;
                orderTypePriceObj[name] = value;
                this.setState(orderTypePriceObj);
            }


        } else {
            this.setState({orderTypePriceObj: evt})
        }
    }

    handlePlatePriceChange(evt) {
        let {byColorPrice} = this.state
        let parentElem = evt.currentTarget.parentNode
        let name = parentElem.getAttribute('data-name');
        let colorName = evt.target.name;
        let id = parentElem.getAttribute('data-id');
        let value = evt.target.value;

        var referenceNode = evt.target


        if (parseInt(value) <= 0) {
            if (!referenceNode.classList.contains('error')) {
                referenceNode.classList.add('error')
                var newNode = document.createElement('div');
                newNode.style.cssText = 'position: absolute;bottom: -16px;font-size: 10px;color: red;';
                newNode.textContent = "Field value must be greater than 0";
                referenceNode.after(newNode);
            }
        } else {
            if (referenceNode.classList.contains('error')) {
                referenceNode.classList.remove('error');
                referenceNode.nextSibling.remove();
            }
            byColorPrice[id][colorName][name] = value
            this.setState({byColorPrice})
        }

    }


    handleOrderColorsObj(data) {

        let dataForState = {}

        Object.keys(data).map((key) => {
            let item = data[key]
            let colors

            let itemId = item.id
            let itemColors = item.colors
            dataForState[itemId] = dataForState[itemId] === undefined ? {} : dataForState[itemId]

            if (itemColors !== undefined) {
                Object.keys(itemColors).map((key) => {
                    let item = itemColors[key]
                    let colorName = item.colorName
                    let price = item.price
                    let priceDiscount = item.priceDiscount

                    dataForState[itemId] = dataForState[itemId] === undefined ? {} : dataForState[itemId]
                    dataForState[itemId][colorName] = dataForState[itemId][colorName] === undefined ? {} : dataForState[itemId][colorName]
                    dataForState[itemId][colorName]['price'] = price
                    dataForState[itemId][colorName]['priceDiscount'] = priceDiscount
                })
            }
        })
        this.setState({byColorPrice: dataForState, byColorsObj: data})
    }

    handleChangeFolderName(val) {
        this.setState({folderId: val.target.value})
    }

    componentDidMount() {
        this.init()
    }

    async init() {
        const getParams = this.props.match.params;
        this.setState({projectId: getParams.id})
        let currentComponent = this
        const globalLoader = document.getElementById('global-loader');
        if (getParams.id) {
            this.setState({editPage: true});
            await axios.get(`${process.env.REACT_APP_API_URL}/orders/${getParams.id}`,
                {
                    headers: {Authorization: authHeader().Authorization}
                }).then(async orderRes => {
                let response = orderRes.data;
                if(response.code === 200 && Object.values(response.orderTypes).length > 0){
                    let orderTypesValue = response.orderTypes;
                    this.setState({orderTypesValue: orderTypesValue})
                }
                if (response.code === 200 && Object.values(response.order).length > 0) {
                    let supportAdmins = {};
                    let dieCutTemplate = {}
                    let shippingAddressId = null;
                    let billingAddressId = null;
                    let shippingAddress = {};
                    let billingAddress = {};
                    let members = {};
                    let supportAdminsArr = Object.values(response.order.orderSupportAdmins);
                    let addresses = Object.values(response.order.orderAddresses);
                    let orderMembers = Object.values(response.order.orderMembers);
                    let paymentShipping = response.order.paymentShipping;
                    let filesArr = response.files;
                    let webCenterFiles = [];

                    let product = {
                        id: response.order.orderProduct.product.id,
                        optionsJson: response.order.orderProduct.optionsJson,
                    };
                    let productPrice = {
                        price: response.order.orderProduct.price,
                        per_price: response.order.perColorPrice,
                        salePrice: response.order.orderProduct.discount,
                    };
                    let productType = response.order.orderProduct.product.type.key;
                    this.setState({productType: productType});
                    let statusById = response.order.orderStatuses.key;
                    this.setState({statusById: statusById});
                    let userDeleted = response.order.createdUser.deleted
                    this.setState({userDeleted: userDeleted})

                    // this.setState({product: product});
                    // this.setState({productPrice: productPrice});
                    // this.setState({orderProduct: response.order.orderProduct});
                    if (response.order.orderFiles.length > 0) {
                        dieCutTemplate = response.order.orderFiles
                    }
                    let setField = {
                        userId: response.order.userId,
                        selectedEmail: response.order.user.email,
                        statusId: response.order.statusId,
                        orderId: response.order.orderId,
                        isTesting: response.order.isTesting,
                        price: response.order.price,
                        salePrice: response.order.salePrice,
                        discount: response.order.discount,
                        paid: response.order.paid,
                        weight: response.order.weight,
                        platform: response.order.platform,
                        status: response.order.status,
                        deleted: response.order.deleted,
						customer:response.order.customer,
                        deviceId: response.order.deviceId,
                        statusDescription: (response.order.orderStatusesHistory) ? response.order.orderStatusesHistory.description : '',
                        shippingPrice: (response.order.shippingPrice) ? response.order.shippingPrice : 0,
                        vat: response.order.vat,
                        perColorPrice: response.order.perColorPrice,
                        totalExclVat: response.order.totalExclVat,
                        totalInclVat: response.order.totalInclVat,
                        groupId: response.order.user.accountInfo.groupId
                    };
                    // files

                    //support admins
                    if (supportAdminsArr.length > 0) {
                        supportAdminsArr.map(function (element, index) {
                            let adminId = element.adminId;
                            let email = element.adminUsers.email
                            supportAdmins[adminId] = email
                        })
                    }
                    //addresses
                    if (addresses.length > 0) {
                        addresses.map(function (element, index) {
                            if (element.type === "billing") {
                                billingAddress = {
                                    firstname: element.firstname,
                                    middlename: element.middlename,
                                    lastname: element.lastname,
                                    company: element.company,
                                    street: element.street,
                                    city: element.city,
                                    countryId: element.countryId,
                                    countryCode: element.countryCode,
                                    region: element.region,
                                    postcode: element.postcode,
                                    telephone: element.telephone,
                                    fax: element.fax
                                }
                                billingAddressId = element.addressId

                            } else {
                                shippingAddress = {
                                    firstname: element.firstname,
                                    middlename: element.middlename,
                                    lastname: element.lastname,
                                    company: element.company,
                                    street: element.street,
                                    city: element.city,
                                    countryId: element.countryId,
                                    countryCode: element.countryCode,
                                    region: element.region,
                                    postcode: element.postcode,
                                    telephone: element.telephone,
                                    fax: element.fax
                                }
                                shippingAddressId = element.addressId

                            }
                        })
                        //const {field} = this.state;
                        this.setState({paymentMethod: paymentShipping && paymentShipping.payment_method_id ? paymentShipping.payment_method_id : null})
                        this.setState({shippingMethod: paymentShipping && paymentShipping.shipping_method_id ? paymentShipping.shipping_method_id : null})
                        this.setState({orderTypeId: paymentShipping && response.order.orderSelectedTypes ? response.order.orderSelectedTypes : null})
                        // field.price +=  paymentShipping.shipping_price;
                        //this.setState(field);
                    }
                    if (orderMembers.length > 0) {
                        orderMembers.map(function (element, index) {
                            members[element.userId] = element.user.email
                        })
                    }


                    // this.setState({projectName: response.order.projectName});
                    // this.setState({projectDescription: response.order.projectDescription});

                    // this.setState({field: setField})
                    // this.setState({supportAdmins: supportAdmins})
                    // this.setState({billingAddressObj: billingAddress})
                    // this.setState({shippingAddressObj: shippingAddress})
                    // this.setState({billingAddressId: billingAddressId})
                    // this.setState({shippingAddressId: shippingAddressId})
                    // this.setState({members: members})
                    // if(!response.order.isTesting && !response.files ){
                    //     this.setState({error: {text: "Something went wrong with files" ,show: true}})
                    //     return;
                    // }
                    if (filesArr) {
                        const filesNewObjLoopResult = filesArr.map(function (element, index) {
                            //
                            let get_viewer = ""
                            let folderName = element.folder_name[0]
                            let document_version_id = element.document_version_id[0]
                            let document_id = element.document_id[0]
                            let document_name = element.document_name[0]
                            let document_version = element.document_version[0]
                            let viewable = element.viewable[0]
                            let approvalCycleInfo = element.approval_cycle_info[0]
                            let approvalInfoString = element.approval_info_string ? element.approval_info_string[0] : ""
                            if (viewable === "true") {
                                get_viewer = element.get_viewer[0]
                            }

                            webCenterFiles = {
                                folderName: folderName,
                                documentVersionId: document_version_id,
                                documentId: document_id,
                                documentName: document_name,
                                documentVersion: document_version,
                                viewable: viewable,
                                getViewer: get_viewer,
                                projectId: response.order.orderId,
                                approvalCycleInfo: approvalCycleInfo,
                                approvalInfoString: approvalInfoString
                            }
                            return webCenterFiles

                        })
                        const filesNewObjResult = await Promise.all(filesNewObjLoopResult);
                        this.setState({webCenterFiles: filesNewObjResult})
                    }

                    this.setState({
                        dieCutTemplate,
                        product: product,
                        orderProduct: response.order.orderProduct,
                        productPrice: productPrice,
                        projectName: response.order.projectName,
                        projectDescription: response.order.projectDescription,
                        currierName: response.order.orderTrackingInfo ? response.order.orderTrackingInfo.currierName : '',
                        trackingNumber: response.order.orderTrackingInfo ? response.order.orderTrackingInfo.trackingNumber : '',
                        trackingNumberUrl: response.order.orderTrackingInfo ? response.order.orderTrackingInfo.trackingNumberUrl : '',
                        field: setField,
                        supportAdmins: supportAdmins,
                        billingAddressObj: billingAddress,
                        shippingAddressObj: shippingAddress,
                        billingAddressId: billingAddressId,
                        shippingAddressId: shippingAddressId,
                        members: members
                    }, () => {
                        setTimeout(function () {
                            currentComponent.setState({
                                _pageIsReady: true
                            })
                        }, 700)
                    });

                    if(globalLoader && globalLoader.classList.contains("show")) {
                        globalLoader.classList.remove("show");
                    }
                } else {
                    window.location.href = `/users`;
                }
            });
        } else {
            setTimeout(function () {
                currentComponent.setState({
                    _pageIsReady: true
                })
            }, 700)
        }
    }

    componentDidUpdate(prevProps, prevState) {

    }

    render() {
        { console.log("state editPage", this.state.editPage)}
        if (this.state._pageIsReady !== true) {
            return (
                <div id="loader" className="loader noBG show circle">
                    <svg
                        width="200px"
                        height="200px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="xMidYMid"
                        className="lds-rolling"
                    >
                        <circle
                            cx="50"
                            cy="50"
                            fill="none"
                            ng-attr-stroke="{{config.color}}"
                            ng-attr-stroke-width="{{config.width}}"
                            ng-attr-r="{{config.radius}}"
                            ng-attr-stroke-dasharray="{{config.dasharray}}"
                            stroke="#B4B000"
                            strokeWidth="2"
                            r="30"
                            strokeDasharray="141.37166941154067 49.12388980384689"
                            transform="rotate(159.346 50 50)"
                        >
                            <animateTransform
                                attributeName="transform"
                                type="rotate"
                                calcMode="linear"
                                values="0 50 50;360 50 50"
                                keyTimes="0;1"
                                dur="1.4s"
                                begin="0s"
                                repeatCount="indefinite"
                            ></animateTransform>
                        </circle>
                    </svg>
                </div>
            )
        }

        const {
            members,
            productPrice,
            product,
            productType,
            statusById,
            dieCutTemplate,
            field,
            supportAdmins,
            shippingAddressId,
            billingAddressId,
            billingAddressObj,
            shippingAddressObj,
            files,
            projectName,
            projectDescription,
            currierName,
            trackingNumber,
            trackingNumberUrl,
            webCenterFiles,
            byColorPrice,
        } = this.state;
        let show = this.state.editPage && (this.state.field.statusId == 17 || this.state.field.statusId == 18 || this.state.field.statusId == 5);


        return (

            <div className="hello custom-container">
                <div id="global-loader" className="loader circle">
                    <svg
                        width="200px"
                        height="200px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="xMidYMid"
                        className="lds-rolling"
                    >
                        <circle
                            cx="50"
                            cy="50"
                            fill="none"
                            ng-attr-stroke="{{config.color}}"
                            ng-attr-stroke-width="{{config.width}}"
                            ng-attr-r="{{config.radius}}"
                            ng-attr-stroke-dasharray="{{config.dasharray}}"
                            stroke="#B4B000"
                            strokeWidth="2"
                            r="30"
                            strokeDasharray="141.37166941154067 49.12388980384689"
                            transform="rotate(159.346 50 50)"
                        >
                            <animateTransform
                                attributeName="transform"
                                type="rotate"
                                calcMode="linear"
                                values="0 50 50;360 50 50"
                                keyTimes="0;1"
                                dur="1.4s"
                                begin="0s"
                                repeatCount="indefinite"
                            ></animateTransform>
                        </circle>
                    </svg>
                </div>

                <ErrorDialog open={this.state.error.show} text={this.state.error.text}
                             onChangeValue={this.handleChangeErrorPopup}/>
                {this.state.field.orderId ? <Messenger orderId={this.state.field.orderId}/> : ''}
                <Row>
                    {/*{(show) &&*/}
                    {this.state.field.customer?
                        <><Col xs="12" sm="4">
                            <div className="order-card">
                                <OrderTypes disabled onChangeValue={this.handleOrderTypeChange}
                                    // value={this.state.orderTypeId} orderId={this.props.match.params.id}
                                            value={this.state.orderTypesValue} orderId={this.props.match.params.id}
                                            update={this.state.editPage}/>
                            </div>
                        </Col>
                        <Col xs="12" sm="2">
                            <div className="order-card">
								<FormLabel component="legend">Customer</FormLabel>
                                <div variant="subtitle1" className="text-break" >{this.state.field.customer.firstname+" "+this.state.field.customer.lastname}</div>
                            </div>
                        </Col></>
                        :
                        <Col xs="12" sm="6">
                            <div className="order-card">
                                <OrderTypes disabled onChangeValue={this.handleOrderTypeChange}
                                    // value={this.state.orderTypeId} orderId={this.props.match.params.id}
                                            value={this.state.orderTypesValue} orderId={this.props.match.params.id}
                                            update={this.state.editPage}/>
                            </div>
                        </Col>
                    }
                    {/*}*/}
                    <Col xs="12" sm="6">
                        <Statuses onChangeValue={this.handleStatusChange} value={this.state.field.statusId}
                                  descriptionValue={this.state.field.statusDescription}
                                  orderId={this.props.match.params.id} isDisabled={this.state.userDeleted}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="6" md="3">
                        <div className="order-card">
                            <UsersPopup title="Select user" error={this.state.error.show} parentId={0}
                                        orderId={this.props.match.params.id} selectedValue={field.selectedEmail}
                                        showSelected={true} userId={this.state.field.userId}
                                        groupId={this.state.field.groupId} onChangeValue={this.handleChangeUserValue}/>
                        </div>
                    </Col>

                    {(field.userId) &&
                    <Col xs="12" sm="6" md="3">
                        <div className="order-card">
                            <MembersList members={members} onChange={this.deleteMember}/>
                            <UsersPopup title="Select members" parentId={field.userId} error={this.state.error.show}
                                        showSelected={false} selectedValue={members} userId="" groupId={null}
                                        onChangeValue={this.handleChangeMemberValue} isDisabled={this.state.userDeleted}/>
                        </div>
                    </Col>
                    }
                    <Col xs="12" sm="6" md="3">
                        {/* Order name , description */}
                        <div className="order-card">
                            <TextField
                                onChange={this.handleProjectData}
                                name="name"
                                value={projectName}
                                placeholder="Project Name"
                                className="displayBlock"
                                disabled={this.state.userDeleted}
                            />
                        </div>
                    </Col>
                    <Col xs="12" sm="6" md="3">
                        <div className="order-card">
                            <TextField
                                onChange={this.handleProjectData}
                                name="description"
                                value={projectDescription}
                                placeholder="Project Description"
                                multiline={true}
                                rows={1}
                                rowsMax={4}
                                className="displayBlock"
                                disabled={this.state.userDeleted}
                            />
                        </div>
                    </Col>
                </Row>
                {/*currier name and tracking number*/}
                {(show && this.state.field.statusId == 18) &&
                <Row>
                    <Col xs="12" sm="6" md="3">
                        <div className="order-card min-h-sm">
                            <TextField
                                onChange={this.handleCurrierName}
                                name="currierName"
                                value={currierName}
                                placeholder="Currier Name"
                                multiline={true}
                                rows={1}
                                rowsMax={4}
                            />
                        </div>
                    </Col>
                    <Col xs="12" sm="6" md="3">
                        <div className="order-card min-h-sm">
                            <TextField
                                onChange={this.handleTrackingNumber}
                                name="trackingNumber"
                                value={trackingNumber}
                                placeholder="Tracking Number"
                                multiline={true}
                                rows={1}
                                rowsMax={4}
                            />
                        </div>
                    </Col>
                    <Col xs="12" sm="6" md="3">
                        <div className="order-card min-h-sm">
                            <TextField
                                onChange={this.handleTrackingUrl}
                                name="trackingNumberUrl"
                                value={trackingNumberUrl}
                                placeholder="Tracking Number Url"
                                multiline={true}
                                rows={1}
                                rowsMax={4}
                            />
                        </div>
                    </Col>
                </Row>
                }

                {/* Products */}
                <div className="orderContext">
                    {/*<Products isTesting={field.isTesting}  onChangeValue={this.handleProductChange} />*/}
                    <ProductsPopup title="Select product" error={this.state.error.show} saveProduct={(props) => {
                        this.handleSaveProduct(props)
                    }} productPrice={this.handleProductPrice} product={this.handleProduct}
                                   selectedValue={this.state.orderProduct} orderProduct={this.state.orderProduct}
                                   deleteProduct={this.handleDeleteProduct} showSelected={true}
                                   userId={this.state.field.userId} onChangeValue={this.handleChangeProductValue} isDisabled={this.state.userDeleted}/>
                </div>

                {/**{!field.isTesting && */}
                <div className="orderContext">
                    <FormControl className="w-100 overflow-x-auto">
                        <div className="flex-box">
                            <div className="orderContext noBorder noPadding select-box">
                                <InputLabel id="demo-simple-select-label">Folder</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.folderId}
                                    onChange={this.handleChangeFolderName}
                                >
                                    <MenuItem value={process.env.REACT_APP_FOLDERID_CUSTOMER}>Customer</MenuItem>
                                    <MenuItem value={process.env.REACT_APP_FOLDERID_3D_APPROVE}>3D Approve</MenuItem>
                                    <MenuItem value={process.env.REACT_APP_FOLDERID_SOFT_APPROVE}>Soft
                                        approve</MenuItem>
                                </Select>
                            </div>
                            {showOrderUploadBtn ? (
                                <div className="orderContext noBorder noPadding textAlignRight">
                                    <Button variant="outlined" color="primary"
                                            onClick={() => document.getElementById('file2').click()}>Upload
                                        file</Button>
                                    <input
                                        accept="application/pdf, image/*"
                                        id="file2"
                                        name="file2"
                                        onChange={this.handleFileSelect}
                                        type="file"
                                        style={{display: "none"}}
                                        multiple
                                    />

                                </div>
                            ) : ''}

                        </div>
                        {
                            (files.length > 0) ?
                                files.map(function (element, index) {
                                    if (element.type !== 'len') {
                                        return <label key={index}><span
                                            className="font-weight-bold">File name:</span> {element.name} </label>
                                    }
                                })
                                : ""
                        }
                        {
                            (webCenterFiles.length > 0) ?
                                <ViewFiles value={webCenterFiles} error={this.state.error.show}
                                           message={this.state.error.text} onChangeValue={this.handleViewFiles}
                                           orderId={this.props.match.params.id}/>
                                :
                                ""
                        }
                    </FormControl>
                </div>

                {/*}*/}
                <div className="flex-box">

                    {dieCutTemplate.length > 0 &&
                    <div className="orderContext">
                        <table className="MuiTable-root" aria-label="simple table">
                            <tbody className="MuiTableBody-root">
                            <tr className="MuiTableRow-root">
                                <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft">Die-Cut
                                    Template
                                </td>
                            </tr>
                            {
                                dieCutTemplate.map(function (element, index) {
                                    return (
                                        <tr className="MuiTableRow-root">
                                            <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft">
                                                <a href={element.file} target="_blank">{element.filename}</a>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>

                    </div>
                    }
                    {/*
                (this.state.editPage && this.state.field.statusId==6 && this.state.productType !== 'fingerprint') &&
                    <div className="orderContext">
                        <Table aria-label="simple table">
                            <TableBody>
                                <TableRow >
                                    <TableCell align="left">
                                        <div className="flex-box">
                                            Upload LEN File
                                            <div className="orderContext noBorder noPadding textAlignRight">
                                                <Button variant="outlined" color="primary"  onClick={() => document.getElementById('file3').click()} >Upload file</Button>
                                                <input
                                                    accept="application/pdf, image/*"
                                                    id="file3"
                                                    name="file3"
                                                    onChange={this.handleLenFile}
                                                    type="file"
                                                    style={{display:"none"}}
                                                    multiple
                                                />
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>

                                {
                                    (files.length > 0) ?
                                        files.map(function(element, index){
                                            if (element.type == 'len') {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell align="left">
                                                            <div className="flex-box">
                                                                <span className="font-weight-bold">File name:</span>  {element.name}
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            }
                                        })
                                        :""
                                }
                            </TableBody>
                        </Table>
                    </div>
                    */
                    }
                </div>

                {/*len file upload*/}
                {/* Address */}
                {(show) &&
                <div className="orderContext">
                    <div className="orderContext-half">
                        <Addresses value={billingAddressId} title="Billing Addresses" type={null} disabled={show}
                                   userId={field.userId} onChangeValue={this.handleBillingAddressChange}/>
                        <AddressesForm value={billingAddressObj} disabled={show} addressId={billingAddressId}
                                       onChangeValue={this.handleBillingAddressObjChange}/>

                    </div>
                    {(show) && (field.userId && this.state.orderTypeId == 3 && this.state.field.statusId != 0) &&
                    <div className="orderContext-half">
                        <Addresses value={shippingAddressId} title="Shipping Addresses" type="useAsShipping"
                                   disabled={shippingAddressObj.sameAsBilling} userId={field.userId}
                                   onChangeValue={this.handleShippingAddressChange}/>
                        <AddressesForm value={shippingAddressObj} disabled={show} addressId={shippingAddressId}
                                       onChangeValue={this.handleShippingAddressObjChange}
                                       checkBoxValue={shippingAddressObj.sameAsBilling}
                                       onChangeCheckbox={this.handleShippingAddressCheckboxChange}/>
                    </div>
                    }
                </div>
                }

                {/* Comment */}
                {/* <AdminsPopup  /> */}
                <div className="flex-box">
                    <div className="orderContext">
                        <AdminsList admins={supportAdmins} onChange={this.deleteSupportAdmins}/>
                        {Object.keys(supportAdmins).length === 0 &&
                        <AdminsPopup title="Select support admins" error={this.state.error.show} showSelected={false}
                                     selectedValue="" adminId="" onChangeValue={this.handleChangeSupportAdminValue} isDisabled={this.state.userDeleted}/>
                        }
                    </div>
                </div>

                {/* Payment methods */}
                <div className="flex-box">
                    {(show) &&
                    <div className="orderContext mb-4">
                        <PaymentMethods disabled={show} onChangeValue={this.handlePaymentChange}
                                        value={this.state.paymentMethod} paid={this.state.field.paid}
                                        groupId={field.groupId} userId={field.userId}/>
                    </div>
                    }
                    {((show) || this.state.field.statusId == 6) &&
                    <div className="orderContext mb-4">
                        <OrderTypePrices disabled={show} onChangeValue={this.handleOrderTypePricesObjChange}
                                         colorsObj={this.handleOrderColorsObj} value={this.state.orderTypePriceObj}
                                         orderId={this.props.match.params.id} update={this.state.editPage}
                                         productType={this.state.productType}/>
                    </div>
                    }
                    {((show) || this.state.field.statusId == 6) && Object.keys(byColorPrice).length > 0 &&
                    Object.keys(this.state.byColorsObj).map((item, key) => {
                        let fieldItem = this.state.byColorsObj[key]
                        let itemColors = fieldItem.colors


                        return (
                            <div className="orderContext mb-4">

                                <fieldset className="MuiFormControl-root makeStyles-formControl-279 displayBlock">
                                    <legend className="MuiFormLabel-root">{fieldItem.name + ' Price'}</legend>

                                    <div className="flex-box">
                                        {
                                            Object.keys(itemColors).map((key) => {
                                                let colorItem = itemColors[key]
                                                return (
                                                    <>
                                                        <div>
                                                            <label
                                                                style={{textTransform: 'capitalize'}}>{colorItem.colorName} Price</label><br></br>
                                                            <Input type="number"
                                                                   value={byColorPrice[fieldItem.id][colorItem.colorName]['price']}
                                                                   onChange={this.handlePlatePriceChange}
                                                                   data-id={fieldItem.id} data-name={'price'}
                                                                   name={colorItem.colorName} step="0.01"/>
                                                        </div>
                                                        <div>
                                                            <label
                                                                style={{textTransform: 'capitalize'}}>{colorItem.colorName} Price
                                                                Discount</label><br></br>
                                                            <Input type="number"
                                                                   value={byColorPrice[fieldItem.id][colorItem.colorName]['priceDiscount']}
                                                                   onChange={this.handlePlatePriceChange}
                                                                   data-id={fieldItem.id} data-name={'priceDiscount'}
                                                                   name={colorItem.colorName} step='0.01'/>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>

                                </fieldset>
                            </div>

                        )
                    })
                    }
                    {/*Weight */}
                    {((show) || this.state.field.statusId == 6) &&
                    <div className="orderContext">
                        <Weight disabled={show} onChangeValue={this.handleWeightChange} value={field.weight}/>
                        {this.state.weightError &&
                        <span className="color-secondary ml-3">Weight must be greater than 0</span>}
                    </div>
                    }
                </div>
                {/* Shipping methods After weight */}
                {(show && this.state.orderTypeId == 3) &&
                <div className="orderContext">
                    <ShippingMethods disabled={show} onChangeValue={this.handleShippingChange} weight={field.weight}
                                     city={shippingAddressObj.city} country={shippingAddressObj.countryId}
                                     value={this.state.shippingMethod}/>
                </div>
                }
                {/* Addresses */}
                <div className="flex-box">

                    <div className="orderContext">
                        <SendEmailCheckbox onChangeValue={this.handleSendEmailChange} value={this.state.sendEmail} isDisabled={this.state.userDeleted}/>
                        <SendPushCheckbox lableText='Send notification to Mobile and Web'
                                          onChangeValue={this.handleSendPushChange} value={this.state.sendPush} isDisabled={this.state.userDeleted}/>
                        {/* <SendPushCheckbox lableText='Send notification to Web site' onChangeValue={this.handleSendPushToWebChange} value={this.state.sendPushToWeb} /> */}
                    </div>
                    {(show) &&
                    <div className="orderContext noPadding noBorder">
                        <Table aria-label="simple table" style={{width: 600, padding: 15, border: "1px solid #ccc"}}>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Product Price</TableCell>
                                    <TableCell>{this.financial(this.state.field.price)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Discount</TableCell>
                                    <TableCell>{this.financial(this.state.field.discount)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Shipping & Handling</TableCell>
                                    <TableCell>{this.financial(this.state.field.shippingPrice)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Grand Total Excl. Vat</TableCell>
                                    <TableCell>{this.financial(this.calcTotalExclVat())}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Vat</TableCell>
                                    <TableCell>{this.financial(this.calcVat())}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Grand Total Incl. Vat</TableCell>
                                    <TableCell>{this.financial(this.calcTotalInctVat())}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                    }

                </div>
                {(show) &&
                <div className="pdf-buttons">
                    <Button variant="outlined" color="primary" onClick={this.sendJobOrder}
                            orderId={this.props.match.params.id} className='mr-4'>Send Job Order Pdf</Button>
                    <Button variant="outlined" color="primary" onClick={this.sendInvoice}
                            orderId={this.props.match.params.id}>Send Invoice Pdf</Button>
                </div>
                }
                {accessControl["orders"] &&
                accessControl["orders"].includes("edit") ? (
                    <Button variant="contained" color="primary" onClick={this.createOrder} disabled={this.state.userDeleted}>Save</Button>
                ) : (
                    ""
                )}
            </div>
        )
    }
}

export default Order;
