import React from 'react';
import { withStyles } from '@material-ui/core';
import { 
    Toolbar,
    SaveButton,
    List, 
    Datagrid, 
    Edit,  
    SimpleForm,
    Filter, 
    TextField, 
    EditButton, 
    DisabledInput, 
    TextInput, 
    required,
    minLength
 } from 'react-admin';
 
const validateValue = [required(), minLength(2)];

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CompFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="label" alwaysOn />
    </Filter>
);

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
));

export const SettingsList = (props) => (
    <List filters={<CompFilter />} {...props} bulkActions={false}>
        <Datagrid>
            <TextField source="label" />
            <TextField source="value" />
            <EditButton basePath="/settings" />
        </Datagrid>
    </List>
);

export const SettingsEdit = (props) => (
    <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar />}>
            <DisabledInput source="id" />
            <DisabledInput source="key" />
            <DisabledInput source="label" />
            <TextInput source="value" validate={validateValue}/>
            <DisabledInput source="showInAdmin" />
            <DisabledInput  source="createdAt" />
            <DisabledInput  source="updatedAt" />
        </SimpleForm>
    </Edit>
);