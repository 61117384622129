import React, { Component } from 'react';
import Types from "./Types";
import CalculateLogic from "./CalculateLogic";
import Validations from "./Validations";
import PropTypes from 'prop-types';
import {TextField,FormControl,FormControlLabel,Switch,Button,Select,MenuItem,ListItemText,InputLabel} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Box from '@material-ui/core/Box';
import OptionItemRows from "./OptionItemRows";

import {AppBar, Tab,Tabs, Typography } from '@material-ui/core/';

import BorderColorIcon from '@material-ui/icons/BorderColor';
import ChildFriendlyIcon from '@material-ui/icons/ChildFriendly';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import RichTextInput from "ra-input-rich-text";
import ConfirmDialog from "./confirmDialog"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const useStyles = {
    item: {
        backgroundColor: '#efefef',
        padding: '10px',
        marginTop: '20px',
        flexGrow: 1,
        borderBottom : "2px solid #1027ca",
        //backgroundColor: theme.palette.background.paper,
    },
    button:{
        float: 'right',
        padding: '4px'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: 1,
    },
};
function TabPanel(props) {
    const { children, value, index, option } = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${option}-${index}`}
            aria-label={`simple-tabpanel-${option}-${index}`}

        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index,option) {
    return {
        id: `simple-tab-${option}-${index}`,
        'aria-controls': `simple-tabpanel-${option}-${index}`,
    };
}
class OptionItem extends Component {
    constructor(props){
        super(props);
        this.state={
            options:props.options,
            option:props.option,
            types: props.types,
            attributes:props.attributes,
            validations:props.validations,
            calculationLogics:props.calculationLogics,
            selectedType:{},
            children:[],
            tab:0,
            confirmShow:false
        }
        if(props.option && props.option.id && !props.option.optionId){
            this.state.option['optionId'] = props.option.id;
        }

        this._isMounted = false;
        this.handleChange = this.handleChange.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this)
        this.setType = this.setType.bind(this);
        this.deleteOption = this.deleteOption.bind(this);
        this.addValidationsToObject = this.addValidationsToObject.bind(this);
        this.addCalcLogic = this.addCalcLogic.bind(this);
        this.addRows = this.addRows.bind(this);
        this.setOptionChildren = this.setOptionChildren.bind(this);
        this.handleChangeConfirmPopup = this.handleChangeConfirmPopup.bind(this);
        this.handleHideConfirmPopup = this.handleHideConfirmPopup.bind(this);
    }
    setOptionChildren(children){
        const {option} = this.state;
        option.children = children;
        this.props.changeValue(option);
    }
    handleTabChange = (event, newValue) => {
        this.setState({tab:newValue});
    };
    componentWillReceiveProps(props){
        this._isMounted = true;
        const { calculationLogics, types, validations, option, options, attributes} = props;
        if(props.option && props.option.id && !props.option.optionId){
            option['optionId'] = props.option.id;
        }
        this.setState({calculationLogics, types, validations, option, options,attributes})
    }
    componentWillMount(){
        this._isMounted = true;
    }
    componentWillUnmount(){
        this._isMounted = false;
    }
    setType(type){
        let {option,selectedType} = this.state;
        option.typeId = type.id;
        selectedType = type;
        this.setState({option,selectedType});
        this.props.changeValue(option);
    }
    addCalcLogic(logic){
        const {option} = this.state;
        option.calculateLogicId = logic;
        this.props.changeValue(option);
    }
    addRows(fields){
        const {option} = this.state;
        option.fields = fields;
        this.props.addRows(option);
    }
    deleteOption(event){
        // const {option} = this.state;
        // this.props.deleteOption(option.optionId);
        const {confirmShow} = this.state;
        this.setState({
            confirmShow:true
        })
    }
    addValidationsToObject(validations){
        const { option } = this.state;
        option['validations'] = validations;
        this.setState({option});
        this.props.setValidation(option);
    }
    handleChange(event){
        const { target: { name, value , type} } = event;
        const { option } = this.state;
        option[name] = value && value != null ? value : '';
        if(type === 'checkbox'){
            option[name] =  event.target.checked
        }

        this.setState(option);
        this.props.changeValue(option);
    }
    handleHideConfirmPopup() {
        const {confirmShow} = this.state;
        this.setState({
            confirmShow:false
        })
    }
    handleChangeConfirmPopup(val) {
        const {confirmShow} = this.state;
        this.setState({
            confirmShow:false
        })

        const {option} = this.state;
        this.props.deleteOption(option.optionId);
    }
    render(){

        //if(!this._isMounted) return "";
        const {options,option, calculationLogics, types, validations,tab,attributes} = this.state;
        const selectedType = types[types.findIndex(x=>x.id === option.typeId)];
        const lastIndex = selectedType && selectedType.hasRows ? 2:1;
        return (
            <div className="new-option">
                <ConfirmDialog open={this.state.confirmShow} text={'Are you sure?'} onHandleClose={this.handleHideConfirmPopup} onChangeValue = {this.handleChangeConfirmPopup} />

                <AppBar position="static" color="default">
                    <Tabs
                        value={tab}
                        onChange={this.handleTabChange}
                        variant="fullWidth"
                        indicatorColor="secondary"
                        textColor="secondary"
                        aria-label="icon label tabs example"
                    >
                        <Tab label="Information" icon={<BorderColorIcon />} {...a11yProps(0,option.optionId)} />
                        {selectedType && selectedType.hasRows ?
                            <Tab label="Fields" icon={<MenuOpenIcon />} {...a11yProps(1,option.optionId)} />
                            :
                            null
                        }
                    </Tabs>
                </AppBar>
                <TabPanel value={tab} option={option.optionId} index={0}>
                    <div className='option-items flexible-child column-4'>
                        <Types typeId={option.typeId} types={types} useStyles={useStyles} setType={this.setType} />
                        {
                            selectedType && selectedType.type == 'number' ?
                                <FormControl>
                                    <InputLabel id="demo-simple-select-label">Units</InputLabel>
                                    <Select
                                        value={option.units ? option.units : ""}
                                        name="units"
                                        onChange={this.handleChange}
                                        className={useStyles.textField}
                                    >
                                        <MenuItem key="number" value="number">Number</MenuItem>
                                        <MenuItem key="mm" value="mm">MM</MenuItem>
                                        <MenuItem key="percent" value="percent">Percent</MenuItem>
                                        <MenuItem key="num_of_colors" value="num_of_colors">Number Colors</MenuItem>
                                        <MenuItem key="gears" value="gears">Gears</MenuItem>
                                    </Select>
                                </FormControl>

                                :
                                ""
                        }
                        <FormControl>
                            <InputLabel htmlFor="select-multiple-checkbox" className="custom-label">Label</InputLabel>
                            <Select
                                value={option.label}
                                name="label"
                                onChange={this.handleChange}
                                MenuProps={MenuProps}
                            >
                                {attributes.map(attribute => (

                                    <MenuItem key={attribute.id} value={attribute.an}>
                                        <ListItemText primary={attribute.an} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {
                            !selectedType || !selectedType.hasRows ?

                                <FormControl>
                                    <TextField
                                        id="defaultValue"
                                        label="Default Value"
                                        className={useStyles.textField}
                                        value={option.defaultValue ? option.defaultValue: ""}
                                        name="defaultValue"
                                        onChange={this.handleChange}
                                        margin="normal"
                                    />
                                </FormControl>
                                :
                                ""
                        }
                        <FormControl>
                            <TextField
                                id="sortOrder"
                                label="Sort Order"
                                className={useStyles.textField}
                                value={option.sortOrder}
                                inputProps={{ min: "0",  step: "1" }}
                                name="sortOrder"
                                type="number"
                                step="1"
                                onChange={this.handleChange}
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                id="price"
                                label="Price"
                                className={useStyles.textField}
                                value={option.price}
                                name="price"
                                onChange={this.handleChange}
                                margin="normal"
                            />
                        </FormControl>
                        <CalculateLogic useStyles={useStyles} calculationLogics={calculationLogics} calculateLogicId={option.calculateLogicId} addCalcLogic={this.addCalcLogic}/>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={option.isRequired ? true : false}
                                    onChange={this.handleChange}
                                    name="isRequired"
                                    color="primary"
                                />
                            }
                            label="Is Required"
                        />

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={option.isForCustomTemplate ? true : false}
                                    onChange={this.handleChange}
                                    name="isForCustomTemplate"
                                    color="primary"
                                />
                            }
                            label="Is For Custom Template"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={option.isForOneLine ? true : false}
                                    onChange={this.handleChange}
                                    name="isForOneLine"
                                    color="primary"
                                />
                            }
                            label="is For One Line"
                        />
                        <FormControl>
                            <TextField
                                id="skuValue"
                                label="SKU"
                                className={useStyles.textField}
                                value={option.sku ? option.sku: ""}
                                name="sku"
                                onChange={this.handleChange}
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl fullWidth style={useStyles.margin}>

                            <TextField
                                multiline={true}
                                id="placeHolder"
                                value={option.placeHolder ? option.placeHolder : ""}
                                onChange={this.handleChange}
                                name="placeHolder"
                                label="Description"
                            />
                        </FormControl>
                        {
                            !selectedType || !selectedType.hasRows ?
                                <Validations
                                    styles={useStyles}
                                    option={option.optionId}
                                    allValidations={validations}
                                    validations={option.validations}
                                    addValidationsToObject={this.addValidationsToObject}/> : "" }
                    </div>
                    <Button onClick={this.deleteOption} variant="outlined" color="secondary" className='mt-3'>  <DeleteForeverIcon /> Delete</Button>
                </TabPanel>
                {
                    selectedType && selectedType.hasRows ?
                        <TabPanel value={tab}  option={option.optionId}  index={1}>
                            <OptionItemRows
                                useStyles={useStyles}
                                option={option}
                                type={selectedType}
                                fields={option.fields}
                                options={options}
                                typeId={option.typeId}
                                addRows={this.addRows}/>
                        </TabPanel>
                        :
                        null
                }
            </div>
        )
    }
}
export default OptionItem